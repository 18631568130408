@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif !important;
  overflow-x: hidden;
}

header {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #212529;
}

.nav-area {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
}

.logo {
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin-right: 20px;
}

.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
}

.menu-items {
  position: relative;
  font-size: 14px;
}

.show .menu-items a:hover {
  border-color: brown;
  background: #ffe5e5;
  color: #000;
}
.show .menu-items {
  margin: 0;
}
.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
  border-left: 3px solid #fff;
  transition: all 0.5s;
}

.menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  border-left: 2px solid #fff;
  transition: all 0.6s;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

button span {
  margin-left: 3px;
}

.menu-items > a,
.menu-items button {
  text-align: left;
  padding: 0.5rem 0.5rem;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.menu-items button:hover a {
  color: #8d3030;
}
.menu-items button:hover {
  background-color: unset;
  border-left: 3px solid #8d3030;
}

.menu-items .contact:hover {
  background-color: unset;
  color: #8d3030;
  border-left: 3px solid #8d3030;
}
.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute !important;
  right: 0;
  left: 0;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 12rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

.default-heading {
  color: brown;
  font-weight: bold;
}
.download-link {
  cursor: pointer;
}
.download-link:hover {
  cursor: pointer;
  color: red;
}
.dropdown .menu-items button {
  color: #212529;
}
.dropdown .menu-items button:hover {
  background-color: #ffe5e5;
}

input:invalid {
  border: 2px solid red;
}

.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editor-class {
  /* background-color:lightgray;*/
  padding: 1rem;
  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
  display: none;
}

.editor {
  /*margin: 1rem 0;*/
  display: block;
  width: 100%;
  height: 20rem;
}

.dsihtml {
  margin: 1rem 0;
  display: block;
  width: 100%;
  height: 20rem;
  overflow: auto;
}

.form-header {
  background-color: lightgray;
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.card-container.login-card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.login-card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 50px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

label {
  display: block;
  margin-top: 10px;
}

.page-button {
  position: relative;
  display: block;
  margin: 0.1rem;
  width: 3rem;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid
    var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.page-button:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.page-button-active {
  color: white;
  background-color: var(--bs-pagination-color);
}
