.-m--1 {
    margin: 0.5rem !important;
}

.-mb--1 {
    margin-bottom: 0.5rem !important;
}

.-ml--1 {
    margin-left: 0.5rem !important;
}

.-mr--1 {
    margin-right: 0.5rem !important;
}

.-mt--1 {
    margin-top: 0.5rem !important;
}

.-ml--2 {
    margin-left: 1rem !important;
}

.-d--none {
    display: none;
}

.-d-lg--flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.-d--flex {
    display: flex;
}

.-ta--j {
    text-align: justify;
    color: #292929;
}

.-vh {
    height: 50vh;
}

.-bb {
    padding-bottom: 1rem;
    border-bottom: 1px solid lightgray;
    margin-bottom: 1rem;
}

.grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -8px;
    flex-direction: row;
}

.grid.-no-gutter {
    margin: 0;
}

.col {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    max-width: 100%;
    min-width: 8.33333%;
    padding: 0 8px;
    position: relative;
    //     display: grid;
    //   grid-template-columns: repeat(auto-fill, minmax(50%, 1fr)); /* see notes below */
    //   grid-gap: 1em;
}

.-w--1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 8.33333%;
    flex: 0 1 8.33333%;
    width: 8.33333%;
}

.-w--2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 16.66667%;
    flex: 0 1 16.66667%;
    width: 16.66667%;
}

.-w--3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 25%;
    flex: 0 1 25%;
    width: 25%;
}

.-w--4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 33.33333%;
    flex: 0 1 33.33333%;
    width: 33.33333%;
}

.-w--5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 41.66667%;
    flex: 0 1 41.66667%;
    width: 41.66667%;
}

.-w--6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 50%;
    flex: 0 1 50%;
    width: 50%;
}

.-w--7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 58.33333%;
    flex: 0 1 58.33333%;
    width: 58.33333%;
}

.-w--8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 66.66667%;
    flex: 0 1 66.66667%;
    width: 66.66667%;
}

.-w--9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 75%;
    flex: 0 1 75%;
    width: 75%;
}

.-w--10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 83.33333%;
    flex: 0 1 83.33333%;
    width: 83.33333%;
}

.-w--11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 91.66667%;
    flex: 0 1 91.66667%;
    width: 91.66667%;
}

.-w--12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 100%;
    flex: 0 1 100%;
    width: 100%;
}

/* ICON */

svg.xs {
    height: 12px !important;
    width: 12px !important;
}

svg.sm {
    height: 16px !important;
    width: 16px !important;
}

svg.sm-2 {
    height: 20px !important;
    width: 20px !important;
}

svg.sm-3 {
    height: 24px !important;
    width: 24px !important;
}

svg.md {
    height: 32px !important;
    width: 32px !important;
}

svg.lg {
    height: 64px !important;
    width: 64px !important;
}

.svg.xl {
    height: 96px !important;
    width: 96px !important;
}

svg.xxl {
    height: 128px !important;
    width: 128px !important;
}

.tabs {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
}

.tabs > li {
    display: inline-block;
    /*margin-left: 1.5rem;*/
    padding: 0 1rem;
    border-right: white 1px solid;
}

.tabs > li.-active > a {
    color: #0262b9;
}

.tabs > li > a {
    color: white;
    cursor: pointer;
    display: block;
    font-size: 1rem;
    font-weight: 500;
    text-decoration: none;
    /* padding: 0.5rem 0;*/
    transition: all 0.2s;
}

.tabs > li > a:hover {
    color: #c8d44c;
}

.header {
    background-color: #fff;
    -webkit-box-shadow:
        0 1px 2px 0 rgba(0, 0, 0, 0.04),
        0 2px 2px 0 rgba(0, 0, 0, 0.12);
    box-shadow:
        0 1px 2px 0 rgba(0, 0, 0, 0.04),
        0 2px 2px 0 rgba(0, 0, 0, 0.12);
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px - 20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    -webkit-box-orient: vertical;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    height: auto;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.header,
.header__content {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-direction: normal;
    position: relative;
    width: 100%;
}

.header__content {
    -webkit-box-orient: horizontal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0 0.5rem;
    z-index: 999;
    box-shadow: 0px 8px 12px 0 rgba(0, 0, 0, 0.12);
}

@media only screen and (min-width: 576px) {
    .header__content {
        padding: 0 1rem;
    }
}

@media only screen and (min-width: 992px) {
    .header__content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding: 0 1.5rem;
    }
}

.header__content ~ .header__toolbar {
    height: 3rem;
}

.header__content ~ .header__toolbar:hover {
    cursor: pointer;
}

.header__content > div:last-child.header__brand .brand {
    margin: 0;
}

.header__brand {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    cursor: pointer;
}

@media only screen and (min-width: 992px) {
    .header__brand {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }
}

.header__brand .brand {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.header__brand .header__title {
    color: #242526;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
}

@media only screen and (min-width: 992px) {
    .header__brand .header__title {
        font-size: 1.125rem;
        margin-right: 1rem;
    }
}

.header__start {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

@media only screen and (min-width: 992px) {
    .header__start {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
    }
}

.header__end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    padding-top: 0;
}

.header__end,
.header__end .tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.header__end .button {
    position: relative;
}

.header__end .button .badge {
    border-radius: 100%;
    right: 25%;
    line-height: 0.875rem;
    position: absolute;
    top: 10%;
    z-index: 1;
}

.header__end .button .badge.-dark {
    background-color: #083176;
    border-color: #083176;
}

.header__end .divider.-vertical {
    height: 2rem;
}

.header .search {
    max-width: 14rem;
    min-width: 2rem;
    position: relative;
}

.header .search,
.header .search.-collapsed .input__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.header .search.-collapsed .input__wrapper {
    background: #fff;
    position: absolute;
    right: 0;
}

.header .search.-collapsed .input {
    background-color: transparent;
    border-color: transparent;
    border-radius: 0.25rem;
    cursor: pointer;
    padding-left: 0;
    -webkit-transition:
        border-color 0.4s ease-in-out,
        padding-left 0.4s ease-in-out,
        width 0.4s ease-in-out;
    transition:
        border-color 0.4s ease-in-out,
        padding-left 0.4s ease-in-out,
        width 0.4s ease-in-out;
    width: 1rem;
    z-index: 2;
}

.header .search.-collapsed .input:focus,
.header .search.-collapsed .input:hover {
    padding-left: 0.75rem;
    width: 14rem;
}

.header .search.-collapsed .input:hover {
    border-color: #acb0b5;
}

.header .search.-collapsed .input:focus {
    border-color: #0c9ed9;
    cursor: auto;
}

.header .search.-collapsed .input:focus ~ .icon-search {
    z-index: 2;
}

.header .search.-collapsed .input ~ .icon-search {
    z-index: 1;
}

.header .search .input {
    border-radius: 0.25rem;
}

.header.-impersonation {
    padding-top: 0.5rem;
}

.header .impersonation-bar {
    -webkit-box-shadow: inset 0 4px 0 0 #dadee2;
    box-shadow: inset 0 4px 0 0 #dadee2;
    color: #242526;
    font-size: 0.8125rem;
    left: 0;
    line-height: 1.5rem;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}

.header .impersonation-bar,
.header .impersonation-bar__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.header .impersonation-bar__content {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #dadee2;
    padding: 0 0.5rem;
    position: relative;
}

.header .impersonation-bar__content:before {
    left: -1rem;
    -webkit-transform: skewX(45deg);
    transform: skewX(45deg);
}

.header .impersonation-bar__content:after,
.header .impersonation-bar__content:before {
    background-color: #dadee2;
    content: "";
    display: block;
    height: 1.5rem;
    position: absolute;
    top: 0;
    width: 1.5rem;
    z-index: -1;
}

.header .impersonation-bar__content:after {
    right: -1rem;
    -webkit-transform: skewX(-45deg);
    transform: skewX(-45deg);
}

.header .impersonation-bar__content .impersonation-bar__divider {
    margin: 0 0.25rem;
}

.header .impersonation-bar__label {
    margin-right: 0.5rem;
}

.header .impersonation-bar__username {
    font-weight: 500;
    margin-right: 0.25rem;
    max-width: 12rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.header__toolbar {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    font-size: 0.875rem;
    height: 2rem;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 0;
    padding: 0 0.5rem;
    position: relative;
    width: 100%;
    height: 2.5rem;
    background-color: brown;
    padding: 25px 10px !important;
}

@media only screen and (min-width: 576px) {
    .header__toolbar {
        padding: 0 1rem;
    }
}

@media only screen and (min-width: 992px) {
    .header__toolbar {
        padding: 0 1.5rem;
    }
}

.header__toolbar ~ .header__content {
    height: auto;
    min-height: 3.5rem;
}

/*.header__toolbar .tabs:not(.-vertical):not(.-responsive) {
  height: 2.5rem;
}*/

.header__toolbar .tabs:not(.-vertical):not(.-responsive) > li > a {
    font-size: 0.9rem;
    /* padding: .5rem 0;*/
}

.header__toolbar .tabs:not(.-vertical):not(.-responsive) > li.dropdown.-active > a {
    border-bottom-width: 0.0625rem;
    padding-bottom: 0.4375rem;
}

.header__toolbar .tabs:not(.-vertical):not(.-responsive) > li.dropdown .dropdown__trigger {
    padding-right: 1rem;
}

.header__toolbar .tabs:not(.-vertical):not(.-responsive) > li.dropdown .dropdown__trigger:after {
    top: 0.5rem;
}

.header__toolbar .tabs:not(.-vertical):not(.-responsive) > li.sliding-border {
    height: 0.0625rem;
}

.header__toolbar .tabs:not(.-vertical):not(.-responsive):not(.-sliding) > li.-active > a {
    padding-bottom: 0.4375rem;
}

.header__toolbar .header__start {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.header__toolbar .header__end {
    margin-left: 1rem;
    padding-top: 0;
    align-items: center;
}

.header__toolbar .header__end a {
    color: #242526;
    font-size: 0.75rem;
    font-weight: 500;
}

.header__toolbar .header__end a:hover {
    color: #0262b9;
    text-decoration: none;
}

@media only screen and (min-width: 768px) {
    .header.-navbar .header__content .header__start {
        padding-right: 1rem;
    }
}

.header.-navbar .header__content .header__end .toolbar__info {
    border-right: 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding-right: 0.25rem;
}

@media only screen and (min-width: 768px) {
    .header.-navbar .header__content .header__end .toolbar__info {
        border-right: 1px solid #dadee2;
        padding-right: 0.5rem;
    }
}

.header .header__navbar {
    background-color: #0075c9;
    background-image: none;
    border-top: 0.0625rem solid #0075c9;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0 1.5rem;
    width: 100%;
}

.header .header__navbar a {
    color: #fff;
    font-size: 0.75rem;
    font-weight: 500;
}

.header .header__navbar .header__navbar-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    line-height: 1.25rem;
    list-style: none;
    margin: 0.25rem 0;
    padding: 0;
}

.header .header__navbar .header__navbar-menu li {
    border-right: 0.0625rem solid hsla(0, 0%, 100%, 0.5);
    line-height: 1rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    text-transform: uppercase;
    white-space: nowrap;
}

.header .header__navbar .header__navbar-menu li:first-child {
    padding-left: 0;
}

.header .header__navbar .header__navbar-menu li:last-child {
    border-right: 0;
    padding-right: 0;
}

.header .header__navbar .header__end {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    font-size: 0.75rem;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media only screen and (min-width: 768px) {
    .header .header__navbar .header__end {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }
}

.header .header__navbar .header__end li > a > .icon-chevron-right {
    font-size: 0.625rem;
    margin-left: 0.25rem;
}

.header .header__navbar .tabs {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.header .header__navbar .tabs:not(.-vertical).-xs {
    height: 2rem;
}

.header .header__navbar .tabs:not(.-vertical).-xs > li {
    margin-left: 0;
    margin-right: 1.5rem;
}

@media only screen and (min-width: 1200px) {
    .header .header__navbar .tabs:not(.-vertical).-xs > li {
        margin-right: 2rem;
    }
}

.header .header__navbar .tabs:not(.-vertical).-xs > li > a {
    font-size: 0.875rem;
    font-weight: 500;
    padding: 0.5rem 0;
    position: relative;
}

.header .header__navbar .tabs:not(.-vertical).-xs > li:not(.-active) > a:hover {
    text-decoration: underline;
}

.header .header__navbar .tabs:not(.-vertical).-xs > li.-active > a {
    padding-bottom: 0.375rem;
}

.header .header__navbar .tabs:not(.-vertical) li .dropdown .dropdown__trigger {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0.25rem 0;
    text-decoration: none;
}

.header .header__navbar .dropdown__menu .dropdown__menu-item {
    color: #242526;
    font-size: 0.875rem;
    font-weight: 400;
}

.header,
.header.-md {
    min-height: 3.5rem;
}

.header.-md .header__content,
.header .header__content {
    height: 6.5rem;
}

@media only screen and (min-width: 992px) {
    .header.-md .brand,
    .header .brand {
        margin: 0 3rem 0 0;
    }
}

.header.-md .brand:not(.-centurylink):not(.-brightspeed),
.header .brand:not(.-centurylink):not(.-brightspeed) {
    margin: 0 0.5rem;
    width: 8.75rem;
}

@media only screen and (min-width: 768px) {
    .header.-md .brand:not(.-centurylink):not(.-brightspeed),
    .header .brand:not(.-centurylink):not(.-brightspeed) {
        margin: 0 1.5rem 0 0;
        width: 8.1875rem;
    }
}

.header.-md .brand:not(.-centurylink):not(.-brightspeed) > img,
.header.-md .brand:not(.-centurylink):not(.-brightspeed) > svg,
.header .brand:not(.-centurylink):not(.-brightspeed) > img,
.header .brand:not(.-centurylink):not(.-brightspeed) > svg {
    height: 1.125rem;
}

@media only screen and (min-width: 768px) {
    .header.-md .brand:not(.-centurylink):not(.-brightspeed) > img,
    .header.-md .brand:not(.-centurylink):not(.-brightspeed) > svg,
    .header .brand:not(.-centurylink):not(.-brightspeed) > img,
    .header .brand:not(.-centurylink):not(.-brightspeed) > svg {
        height: 1.125rem;
    }
}

.header.-md .header__navbar .header__navbar-menu,
.header .header__navbar .header__navbar-menu {
    margin: 0.25rem 0;
}

.header.-md .header__navbar .header__navbar-menu a,
.header .header__navbar .header__navbar-menu a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 0.75rem;
    line-height: 1rem;
}

.header.-lg {
    min-height: 4rem;
}

.header.-lg .header__content {
    height: 4rem;
}

@media only screen and (min-width: 992px) {
    .header.-lg .brand {
        margin: 0 3rem 0 0;
    }
}

.header.-lg .brand:not(.-centurylink):not(.-brightspeed) {
    margin: 0 0.5rem;
    width: 9.0625rem;
}

@media only screen and (min-width: 768px) {
    .header.-lg .brand:not(.-centurylink):not(.-brightspeed) {
        margin: 0 3rem 0 0;
        width: 9.0625rem;
    }
}

.header.-lg .brand:not(.-centurylink):not(.-brightspeed) > img,
.header.-lg .brand:not(.-centurylink):not(.-brightspeed) > svg {
    height: 1.25rem;
    width: 9.0625rem;
}

@media only screen and (min-width: 768px) {
    .header.-lg .brand:not(.-centurylink):not(.-brightspeed) > img,
    .header.-lg .brand:not(.-centurylink):not(.-brightspeed) > svg {
        height: 1.25rem;
    }
}

.header.-lg .header__navbar a {
    font-size: 0.875rem;
    line-height: 1rem;
}

@media only screen and (min-width: 768px) {
    .header.-xl {
        min-height: 6rem;
    }

    .header.-xl .header__content {
        height: 6rem;
        padding: 0 2rem;
    }
}

@media only screen and (min-width: 768px) {
    .header.-xl .brand:not(.-centurylink):not(.-brightspeed) {
        margin: 0 1rem 0 0;
        width: 10rem;
    }
}

@media only screen and (min-width: 992px) {
    .header.-xl .brand:not(.-centurylink):not(.-brightspeed) {
        margin: 0 3rem 0 0;
    }
}

@media only screen and (min-width: 768px) {
    .header.-xl .brand:not(.-centurylink):not(.-brightspeed) > img,
    .header.-xl .brand:not(.-centurylink):not(.-brightspeed) > svg {
        height: 1.37875rem;
        width: 10rem;
    }
}

.header.-xl .header__navbar {
    min-height: 1rem;
}

.header.-xl .header__navbar a {
    font-size: 1rem;
    line-height: 1.25rem;
}

.header.-xl .header__navbar .header__navbar-menu {
    margin: 0.625rem 0;
}

@media only screen and (min-width: 1200px) {
    .header.-xl .header__navbar .tabs li:not(:first-child) {
        margin-left: 3.5rem;
    }
}

.header.-xl .header__navbar .tabs li .dropdown .dropdown__trigger {
    padding: 0.75rem 0;
}

.header.-xl .header__navbar .dropdown__menu .dropdown__menu-item {
    font-size: 1rem;
    height: 2.5rem;
}

@media (-ms-high-contrast: none) {
    .header__content,
    .header__toolbar ~ .header__content {
        height: 5.5rem;
    }

    .header .search.-collapsed .input__wrapper {
        position: relative;
    }
}

.footer .container {
    padding: 60px 0 50px;
}

.footer {
    h4 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 20px;
    }
}

.footer {
    color: white;
    background: brown;
    border-top: 1px solid #cfd7db;
    padding: 0;
    /*margin-top: 2rem;*/
    p {
        font-size: 14px;
    }
}

.social-icons {
    list-style: none;
    display: flex;
    margin-bottom: 0;

    li {
        padding-left: 0.5rem;
        padding-top: 0.2rem;
    }
}

.news ul li:last-child {
    margin-bottom: 0;
}

.news ul li {
    list-style: disc;
    padding: 2px;
    margin-bottom: 8px;
    color: #222 !important;
    /*border-bottom: 1px solid #efefef;*/
}

.faculty ul li {
    list-style: none;
    border: 1px solid #efefef;
    padding: 0.5rem 1rem;
    margin: 5px 0px;
    & a {
        text-decoration: none;
        display: block;
    }

    &:hover {
        background: lightgrey;
    }
}

.footer-link {
    display: block;
    text-decoration: none;
    color: white;
    font-size: 14px;
    position: relative;
    margin-bottom: 8px;

    &:before {
        content: "";
        position: absolute;
        left: -17px;
        top: 47%;
        transform: translateY(-50%);
        background-color: #fff;
        width: 0;
        height: 2px;
        transition: all 0.2s;
    }

    &:hover {
        color: #dfd481;
        &:before {
            width: 10px;
        }
    }
}

.copyright {
    background-color: lightgray;
    color: black;
    text-align: center;
    padding: 0.5rem;
    font-size: 0.8rem;
}

.about-content h2:before {
    content: "";
    width: 10%;
    height: 2px;
    background-color: brown;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
}

.about-content {
    position: relative;

    a {
        background-color: brown;
        font-weight: 600;
        color: #fff;
        text-decoration: none;
        padding: 5px 20px;
        display: inline-block;
        margin-top: 10px;
        border-radius: 6px;
        position: absolute;
        font-size: 12px;
        left: -150px;
        transition: all 1s;
    }

    &:hover {
        a {
            left: 51.3%;
            transform: translateX(-50%);
        }
    }
}

.about-content h2 {
    margin-bottom: 30px;
    position: relative;
}

.about-content .container {
    margin-top: 0;
    padding-top: 0;
}

.about-content {
    text-align: center;
    padding: 60px;
    background: #f9f9f9;
}

.vc-message .container {
    margin-top: 0;
    padding-top: 0;
}

.vc-message h2:before {
    content: "";
    width: 10%;
    height: 2px;
    background-color: brown;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
}

.vc-message h2 {
    margin-bottom: 30px;
    position: relative;
}

.vc-message {
    padding: 60px 0;
    text-align: center;
    position: relative;
    overflow-x: hidden;
    a {
        background-color: brown;
        font-weight: 600;
        color: #fff;
        text-decoration: none;
        padding: 5px 20px;
        display: inline-block;
        margin-top: 10px;
        border-radius: 6px;
        position: absolute;
        font-size: 12px;
        right: -500px;
        transition: all 1s;
    }

    &:hover {
        a {
            right: 0;
        }
    }
}

.news {
    margin: 0 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
    padding: 0;
}

.news-section {
    .news {
        padding: 0.5rem;
        .heading {
            margin-bottom: 0 !important;
            padding: 15px 15px 0;
        }
    }
    .news-list {
        padding-left: 0;
        padding-right: 0;
        padding-top: 2rem;
        height: 82%;
        .list-item {
            display: flex;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            border-bottom: 1px solid #f1f1f1;
        }
        .firstpart {
            align-items: center;
            width: 20%;
            text-align: center;
            h6 {
                font-size: 18px;
                color: brown;
            }
            p {
                font-size: 14px;
                margin-bottom: 0;
                font-weight: 600;
            }
        }
        .secondpart {
            width: 80%;
        }
    }
}

.heading:before {
    content: "";
    width: 10%;
    height: 2px;
    background-color: brown;
    position: absolute;
    bottom: -10px;
}
.heading {
    margin-bottom: 30px;
    position: relative;
}

.section {
    padding: 60px 0;
    background-color: #f9f9f9;
}

.news a {
    transition: all 0.2s;
}

.news a:hover {
    color: #a3994f;
}

.language-box {
    padding: 3px;
    background: #c9cf34;
    border: 0;
    font-size: 13px;
    font-weight: 600;
}

.menu-items button a {
    border: 0;
}

.menu-items button {
    transition: all 0.2s;
}

// .menu-items button:hover {
//     background: #ffe5e5;
//     color: #000;
// }

.main-nav {
    width: 100%;
    display: grid;
    grid-template-columns: 1rem 0.7fr 2fr 1rem;
}

.logo {
    grid-column: 2/3;
    padding: 1rem 0px;
}
.menu-link {
    grid-column: 3/4;
    display: grid;
    justify-content: end;
    align-items: center;
}
.hamburger-menu {
    display: none;
}

.logo-content {
    display: flex;
    align-content: center;
    text-decoration: none;

    h3 {
        color: brown;
        font-size: 22px;
        margin-bottom: 8px;
        font-weight: 600;
    }

    p {
        color: #aaa256;
        margin-bottom: 0;
        font-size: 12px;
        font-weight: 600;
    }
}

@media (max-width: 998px) {
    .main-nav {
        grid-template-columns: 1rem 10fr 1fr 1rem;
    }

    .logo {
        padding: 0.5rem 0px;
    }

    .menu-link {
        display: none;
    }

    .hamburger-menu {
        display: block;
        margin-top: 0.6rem;
    }

    .logo-content h3 {
        font-size: 20px;
    }

    .mobile-menu-link {
        background-color: white;
        display: grid;
        grid-column: 2/3;
        transition: all 2s linear;
        transform-origin: top;
    }
    .menus {
        display: block;
        padding-left: 0px;
    }
}

.highlights {
    flex-flow: wrap;
    justify-content: center;
    .card {
        background-color: #f9f9f9 !important;
        padding: 10px;
        transition: all 0.3s;
        transform: scale(1);

        .card-body {
            text-align: center;
            padding: 10px 0 0px;
            h5 {
                font-size: 15px;
                color: brown;
                margin-bottom: 0px;
                //margin: 1rem 0rem;
            }
            p {
                font-size: 0.7rem;
                margin-bottom: 0;
            }
        }
        &:hover {
            box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.12);
            background-color: #fff !important;
            transform: scale(1.1);
            border: 0;
        }
    }
}

.read-more-btn:hover {
    color: #fff;
}
.read-more-btn {
    background-color: brown;
    font-weight: 600;
    color: #fff;
    text-decoration: none;
    padding: 5px 20px;
    display: inline-block;
    margin-top: 10px;
    border-radius: 6px;
    font-size: 12px;
}

.tab-content {
    border-bottom: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
    border-left: 1px solid lightgrey;
    padding: 1rem;
}
.nav-link {
    cursor: pointer;
}
.btn-sm {
    --bs-btn-padding-x: 0.25rem;
    --bs-btn-padding-y: 0.05rem;
    margin-right: 0.5rem;
}
.btn:hover {
    div svg{
        fill: white !important;
        stroke: white !important;
    }
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
}
.input_controls {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
}

.control-label {
    text-align: right;
    margin-block-end: 0;
    padding-block-start: 1rem;
    padding-inline-end: 0px;
    margin-right: 0.5rem;
}

.implink {
    margin: 0.5rem 0px;
    color: brown;
    & :hover {
        color: brown;
    }
}

.marquee-container {
    overflow-x: hidden !important;
    display: flex !important;
    flex-direction: row !important;
    position: relative;
    width: 100%;
    height: 2rem;
    border-bottom: 1px solid lightgray;
    border-top: 1px solid lightgray;

    &:hover div {
        animation-play-state: var(--pause-on-hover);
    }

    &:active div {
        animation-play-state: var(--pause-on-click);
    }
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;

    @mixin gradient {
        background: linear-gradient(to right, var(--gradient-color));
    }

    &::before,
    &::after {
        @include gradient;
        content: "";
        height: 100%;
        position: absolute;
        width: var(--gradient-width);
        z-index: 2;
    }

    &::after {
        right: 0;
        top: 0;
        transform: rotateZ(180deg);
    }

    &::before {
        left: 0;
        top: 0;
    }
}

.marquee {
    flex: 0 0 auto;
    min-width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    animation: scroll var(--duration) linear var(--delay) infinite;
    animation-play-state: running;
    animation-delay: var(--delay);
    animation-direction: var(--direction);

    @keyframes scroll {
        0% {
            transform: translateX(0%);
        }
        100% {
            transform: translateX(-150%);
        }
    }
}

.strip {
    padding: 0rem 1rem;
    border-right: 1px solid brown;
    border-left: 1px solid brown;
    text-decoration: none;
}

.light-table tr td {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border: 1px solid #dee2e6;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.mrq {
    height: 26rem;
    margin-top: 1rem;
}

.flex-container {
    display: flex;
    flex-flow: wrap;
    justify-content: space-around;
}

.flex-child-container {
    width: 46%;
}

.img-child-container {
    padding: 0 1rem;
    width: 35%;
}

.text-child-container {
    width: 65%;
}

@media only screen and (min-width: 320px) and (max-width: 426px) {
    .flex-container {
        flex-direction: column;
        align-items: center;
    }
    .flex-child-container {
        width: 100%;
        margin-top: 10px;
    }
    .felx-child-25 {
        width: 100%;
        padding-left: 1rem;
        padding-top: 1rem;
        border-top: gray solid 1px;
    }
    .img-child-container {
        width: 100%;
    }
    .text-child-container {
        padding: 1rem 0;
        width: 100%;
    }
}
